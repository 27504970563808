@import "~@/assets/scss/global.scss";












































































































































































































































































































































.tabs-item {
    ::v-deep {
        .el-tabs__header {
            margin-left: 20px;
        }

        .el-tabs__content {
            overflow: visible;
        }

        .el-tabs__item {
            padding: 0 10px;
        }
    }
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.size-tag {
    font-size: 14px;
    height: 30px;
    margin: 2px 10px;


    .out-stock {
        width: 60px;
        padding: 0 6px;

        ::v-deep {
            .el-input__inner {
                height: 25px !important;
                vertical-align: middle;
                padding-left: 5px;
            }
        }
    }
}

.reset-button {
    height: 22px;
    padding: 0 10px;
    position: absolute;
    top: -45px;
    right: 10px;
}

.setting-item {
    background: #a3a3a3;
    padding: 5px 5px 0 5px;
    font-size: 13px;
}

.setting-item-child {
    // 设置 动画效果
    transition: transform .3s;
    text-align: left;
    cursor: move;
    min-width: 250px;
    display: inline-block;
    background: #fff;
    border-radius: 5px;
    padding: 5px 5px 5px 10px;
    height: 42px;
    line-height: 32px;
    box-sizing: border-box;
    margin: 0 5px 5px 0;

    .el-icon-sort {
        position: absolute;
        right: 20px;
        font-size: 20px;
        line-height: 30px;
    }

    ::v-deep {
        .el-radio {
            margin-right: 10px;

            .el-radio__label {
                padding-left: 3px;
            }
        }
    }
}
