@charset "utf-8";
//框架默认主题色
$base-color-default: #0187fb;
//默认层级
$base-z-index: 999;
//横向布局纵向布局时菜单背景色
$base-menu-background: #21252b;
//菜单文字颜色
$base-menu-color: hsla(0, 0%, 100%, 0.95);
//菜单背景颜色
$base-menu-background: hsla(0, 0%, 4%, 0.95);
//菜单选中文字颜色
$base-menu-color-active: hsla(0, 0%, 100%, 0.95);
//菜单选中背景色
$base-menu-background-active: $base-color-default;
//标题颜色
$base-title-color: #fff;
//字体大小配置
$base-font-size-small: 12px;
$base-font-size-default: 14px;
$base-font-size-big: 16px;
$base-font-size-bigger: 18px;
$base-font-size-max: 22px;
$base-font-color: #606266;
$base-color-blue: $base-color-default;
$base-color-green: #41b882;
$base-color-white: #fff;
$base-color-black: #000;
$base-color-yellow: #ffa91b;
$base-color-orange: #ff6700;
$base-color-red: #f34d37;
$base-color-gray: rgba(0, 0, 0, 0.65);
$base-color-blue: #0075E3;
$base-main-width: 1279px;
$base-border-radius: 4px;
$base-border-color: #dcdfe6;
//输入框高度
$base-input-height: 32px;
//默认paddiing
$base-padding: 20px;

//默认paddiing
$base-margin: 22px;

//默认阴影
$base-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

$base-header-height: 66px;
$base-aside-width: 200px;
$base-footer-height: 40px;
$base-tabs-bar-height: 40px;

$base-main-wrapper-height: calc(100vh - #{$base-header-height} - #{$base-tabs-bar-height} - #{$base-footer-height});

//main的高度
$base-main-height: calc(100vh - #{$base-header-height} - #{$base-tabs-bar-height} - #{$base-footer-height} - (#{$base-margin} * 2) - (#{$base-margin} * 2));

//纵向布局时logo的高度
$base-logo-height: 75px;
//顶部nav-bar的高度
$base-nav-bar-height: 60px;
//顶部多标签页tabs-bar的高度

//顶部多标签页tabs-bar中每一个item的高度
$base-tag-item-height: 34px;
//菜单li标签的高度
$base-menu-item-height: 50px;

//纵向布局时左侧导航未折叠时的宽度
$base-left-menu-width: 256px;
//纵向布局时左侧导航未折叠时右侧内容的宽度
$base-right-content-width: calc(100% - #{$base-left-menu-width});
//纵向布局时左侧导航已折叠时的宽度
$base-left-menu-width-min: 65px;
//纵向布局时左侧导航已折叠时右侧内容的宽度
$base-right-content-width-min: calc(100% - #{$base-left-menu-width-min});
//默认动画
$base-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
  background 0s, color 0s, font-size 0s;
//默认动画长
$base-transition-time: 0.3s;

:export {
  //菜单文字颜色变量导出
  menu-color: $base-menu-color;
  //菜单选中文字颜色变量导出
  menu-color-active: $base-menu-color-active;
  //菜单背景色变量导出
  menu-background: $base-menu-background;
}

.el-autocomplete-suggestion{
    width: auto!important;
}